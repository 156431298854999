import * as React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

// https://recharts.org/en-US/examples/BrushBarChart
// Number of houses sold this month
export default function ChartVolume(props) {
  const { listings } = props;

  const data = {};
  for (const listing of listings) {
    const x = (new Date(listing.house.date_end)).getFullYear() + "-" + ((new Date(listing.house.date_end)).getMonth()+1);
    if (!data[x]) {
      data[x] = 1;
    } else {
      data[x]++;
    }
  }
  const dataArr = [];
  for (const x of Object.keys(data)) {
    dataArr.push({date: x, quantity: data[x]});
  }
  dataArr.sort((a, b) => new Date(a.date) - new Date(b.date)); // default sort behavior with numbers is alphabetical, which is NOT what we want

  // https://recharts.org/en-US/guide/getting-started
  return (
    <BarChart width={600} height={300} data={dataArr}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="quantity" fill="#8884d8" />
    </BarChart>
  );
}