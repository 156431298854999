import React, { useRef, useState, useEffect } from 'react';

export default function AddressSearch(props) {
  const { setCenterPlace, map } = props;

  const ref = useRef();
  // geocoding
  // https://developers.google.com/maps/documentation/javascript/geocoding

  // https://developers.google.com/maps/documentation/places/web-service/autocomplete#location
  // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
  // const input = document.getElementById("pac-input");
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    types: ["geocode"],
    // not working
    // strictBounds: true,
    // location: { lat: 43.752179711930594, lng: -79.4077466457694 },
    // radius: 10000,
  };

  useEffect(() => {
    if (!map) return;
    const autocomplete = new window.google.maps.places.Autocomplete(ref.current, options);
    autocomplete.bindTo("bounds", map);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("address", place);
      setCenterPlace(place);
    });
  }, [map]);

  return (
    <div><a href="/digest/month">Sitemap</a>
      <h5 style={{"marginTop": 0}}>Step 1: <input ref={ref} id="pac-input" type="text" placeholder="Enter a location" /> or Select Neighbourhood Below
      </h5>
    </div>
  );
}


// $("#search").change((e) => {
//   if (!e.target.value.trim()) return;
//   let address = encodeURIComponent(e.target.value);
//   // https://developers.google.com/maps/documentation/geocoding/start
//   $.getJSON(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBl_2ZQ3qxD8sVVctT3faazr2uVkxLJUXQ`, null, function(result) {
//     // console.log(result);
//     if (result.status == "OK") {
//       setMarkerToLocation(result.results[0]);
//     } else {
//       alert("failed to get address with status = " + result.status);
//       console.log(result);
//     }
//   }).fail(function(e) {
//     alert(e.statusText);
//     console.log(e);
//   });  
//   // filterUpdated();
// });
