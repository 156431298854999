import $ from 'jquery';
// import * as React from 'react';
import React, { useRef, useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import AddressSearch from './AddressSearch';

let ccc = 0;
// // POLYGON MGMT
// class PolygonManager {
//   constructor() { this.data = {}; }
//   load() {
//     this.data = localStorage.getItem('hs_map_bounds');
//     if (!this.data) this.data = {};
//     else this.data = JSON.parse(this.data);
//     this.refreshUI();
//   }
//   refreshUI() {
//     const html = Object.keys(this.data).map(name => `<option value="${name}">`).join('');
//     document.getElementById("polygonnames").innerHTML = html;
//   }
//   save() {
//     localStorage.setItem('hs_map_bounds', JSON.stringify(this.data));
//     this.refreshUI();
//   }
//   savePolygon() {
//     const name = $("#polygonname").val();
//     if (name && polygonSelection) {
//       const polygonData = polygonSelection.getPath().getArray().map(point => point.toJSON());
//       this.load();
//       this.data[name] = polygonData;
//       this.save();
//     }
//   }
//   deletePolygon() {
//     const name = $("#polygonname").val();
//     if (name) {
//       this.load();
//       delete this.data[name];
//       this.save();
//     }
//   }
//   loadPolygon() {
//     const name = $("#polygonname").val();
//     this.load();
//     const data = this.data[name];
//     if (data) {
//       clearMapOverlays();
//       polygonSelection = new google.maps.Polygon({
//           paths: data,
//         });
//       polygonSelection.setMap(map);
//       boundsUpdated();
//     }
//   }
// }
// // const polygonManager = new PolygonManager();
// // polygonManager.load();
// // $("#polygonname").focus(function() {
// //   polygonManager.load();
// // });


//   let urlparams = {};
//   // if url parameters were set
//   if (urlparams.neighborhood && urlparams.monthstart) {
//     $("#neighborhood").val(urlparams.neighborhood);
//     filters.neighborhood = urlparams.neighborhood.trim();
//     // TODO need to regex fix this
//     $("#datestart").val(urlparams.monthstart);
//     filters.startDate = urlparams.monthstart;
//     // add one month
//     let nextMonth = (new Date(urlparams.monthstart));
//     nextMonth.setMonth(nextMonth.getMonth()+2);
//     let paddedMonth = nextMonth.getMonth() + 1;
//     paddedMonth = paddedMonth < 10 ? "0" + paddedMonth : paddedMonth;
//     nextMonth = nextMonth.getFullYear() + "-" + paddedMonth;
//     $("#dateend").val(nextMonth);
//     filters.endDate = nextMonth;
//   }
//   // refreshListingsFromServer();
// }

function MyMapComponent(props) {
  let { listings, filters, setFilters, setLoadingStatusMessage, centerPlace, setCenterPlace } = props;
  const ref = useRef();
  const google = window.google;
  ccc += 1;
  let cc = ccc;
  const [map, setMap] = useState();

  const centerLocation = {lat: 43.7631, lng: -79.4909};
  const zoom = 13;
  // const searchRadius = 750;
  // const selectedMarker = null;
  // let masterlistings = [];

  const [searchLocRectangle, setSearchLocRectangle] = useState();
  const [searchLocMarker, setSearchLocMarker] = useState();
  const [polygonSelection, setPolygonSelection] = useState();
  const [rectSelection, setRectSelection] = useState();

  function clearMapOverlays() {
    setSearchLocRectangle(null);
    setSearchLocMarker(null);
    setPolygonSelection(null);
    setRectSelection(null);
  }

  const [mapmarkers, setMapmarkers] = useState(new Map()); // mls as key, gmap marker as value
  const addMapMarker = (key, marker) => {
    setMapmarkers(mapmarkers.set(key, marker)); // i THINK this is ok?
  }

  function showListings(listings) {
    // map.setCenter(centerLocation);

    // add new data to our map and tracking structures
    const bounds = new google.maps.LatLngBounds();
    // console.log(listings.length);
    setLoadingStatusMessage("Adding houses to map");
    for (const listing of listings) {
      const image = {
        url: 'images/simple.png',
        size: new google.maps.Size(16, 16),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 0),
        labelOrigin: new google.maps.Point(0, 0) // offsets the label from the icon
      };
      // const formattedPrice = Math.trunc(listing.list.replace(/\D/g,'') / 1000).toLocaleString() + 'K';
      const formattedPrice = listing.house.price_abbr + "";
      let price = listing.house.price_sold_int;
      price = Math.min(1500000, price);
      price = Math.max(1000000, price);
      const pricenormalized = (price - 1000000) / 500000;
      let color = '#000';
      // base color on the price
      if (pricenormalized > .8) {
        color = '#f00';
      } else if (pricenormalized > .6) {
        color = '#f40';
      } else if (pricenormalized > .4) {
        color = '#f80';
      } else if (pricenormalized > .2) {
        color = '#fa0';
      } else if (pricenormalized >= 0) {
        color = '#ff4';
      }

      const marker = new google.maps.Marker({
        position: listing.house.map,
        icon: image,
        label: {
          text: formattedPrice,
          color: color,
          fontSize: '12px',
          fontWeight: 'bold'
        },
        map: map
      });
      marker.listing = listing; // we add custom data to the marker, hope it doesnt get purged somehow

      addMapMarker(listing.house.id_listing, marker);

      let infowindow = new google.maps.InfoWindow({
        content: `<div>${listing.house.address}</div><img style="max-height:200px;max-width:200px;" src='${listing.meta.image}' loading="lazy" />`, // lazy is SUPER IMPORTANT otherwise we spam HS server when a lot of listings are loaded
        position: listing.house.map,
      });
      google.maps.event.addListener(marker, 'mouseover', function() {
        infowindow.open(map, this);
      });
      google.maps.event.addListener(marker, 'mouseout', function() {
        infowindow.close();
      });
      google.maps.event.addListener(marker, 'click', function() {
        console.log(this.listing);
        window.open("https://housesigma.com/web/en/house/" + this.listing.house.id_listing + "/" + this.listing.house.seo_suffix, "_blank");
      });

      // if (filteredKeywords) {
      //   let infowindow = new google.maps.InfoWindow({
      //     content: filteredKeywords.join(" ")
      //   });
      //   infowindow.open(map,marker);
      // }
      bounds.extend(listing.house.map);
    }

    setLoadingStatusMessage("Reloading charts");

    // show the TTC on the map
    // var kmlLayer = new google.maps.KmlLayer({
    //   url: 'https://stanchovy.com/kml/ttc-v2.kml', // fucking kml files have to be on public websites, cant be localhost
    //   map: map
    // });

    // if filter exists, add it to the bounds list so the map will zoom out to fit it
    if (filters && filters.bounds) {
      for (const point of filters.bounds) {
        bounds.extend(point);
      }
    }
    if (listings.length || filters && filters.bounds)
      map.fitBounds(bounds);

    // map.setZoom(map.getZoom() - 1); // zoom out
    setLoadingStatusMessage(false);


    // BOUNDS CHECKING
    // 5.2022 dont think this is necessary anymore
    // the following codepath is if we just wanted to do bounds filtering locally
    // for now we just do an error check to make sure the server bounds calcluations is at least as exclusive as the google maps library tool, which i trust to be more reliable
    // if (google.maps.geometry) { // might be null if not initialized yet (during browser startup)
    //   let boundsListings = masterlistings.filter((listing) => {
    //     return google.maps.geometry.poly.containsLocation(new google.maps.LatLng(listing.house.map.lat, listing.house.map.lng), polygonSelection)
    //   });
    //   if (boundsListings.length != masterlistings.length) alert("server bounds filter is not working: " + boundsListings.length + "vs" + masterlistings.length);
    // }

    // teardown function
    return function() {
      // clear out all old markers, charts, data
      for (const marker of mapmarkers.values()) {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
      }
      setMapmarkers(new Map());
    }
  }

  let centerMapOnPlace = function(result) {
    // console.log(result.formatted_address);
    let loc = result.geometry.location;
    let lat = typeof loc.lat === 'function' ? loc.lat() : loc.lat;
    let lng = typeof loc.lng === 'function' ? loc.lng() : loc.lng;

    let lat_radius = .005;
    let lng_radius = .008;
    let rect = [{lat: lat-lat_radius, lng: lng-lng_radius}, {lat: lat-lat_radius, lng: lng+lng_radius}, {lat: lat+lat_radius, lng: lng+lng_radius}, {lat: lat+lat_radius, lng: lng-lng_radius}];
    setFilters({...filters, bounds: rect, neighborhood: ""});

    clearMapOverlays();
    setSearchLocRectangle(
      new google.maps.Rectangle({
        strokeColor: "#6c6c6c",
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: "#926239",
        fillOpacity: 0.1,
        map,
        bounds: {
          north: rect[2].lat,
          south: rect[0].lat,
          east: rect[1].lng,
          west: rect[0].lng,
        },
      })
    );
    setSearchLocMarker(new google.maps.Marker({
      position: loc,
      map,
      title: result.formatted_address,
    }));
  }

  // GOOGLE MAP INTERACTIONS - TBD
  // function focusOnMarker(mlsid) {
  //   const marker = mapmarkers[mlsid];
  //   if (marker) {
  //     map.setCenter(marker.getPosition());
  //     new google.maps.event.trigger(marker, 'click' );
  //   }
  // }
  // let hovermarker = null;
  // function hoverlisting(id) {
  //   if (hovermarker) hovermarker.setMap(null);
  //   const listing = masterlistings.find(listing => listing.house.id_listing == id);
  //   if (listing) {
  //     hovermarker = new google.maps.Marker({
  //       position: listing.house.map,
  //       icon: null,
  //       // label: {
  //       //   text: "WAHT",
  //       //   color: "#00f",
  //       //   fontSize: '12px',
  //       //   fontWeight: 'bold'
  //       // },
  //       map: map
  //     });
  //   }
  // }

  function initializeMapObject() {
    let mymap = new google.maps.Map(ref.current, {
      center: centerLocation,
      zoom,
    });


    // calculate the center with getCenter
    let actualMapCenter;
    // Add an event listener that calculates center on idle  
    const a = google.maps.event.addDomListener(mymap, 'idle', function() {
      actualMapCenter = mymap.getCenter();
    });
    // Add an event listener that re-centers the map on resize  
    const b = google.maps.event.addDomListener(window, 'resize', function() {
      mymap.setCenter(actualMapCenter);
    });

    // this allows for user to draw polygons on the map
    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.RECTANGLE, // hand tool by default with null. to go to poly set to: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.RECTANGLE,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      // markerOptions: {
      //   icon:
      //     "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      // },
      polygonOptions: {
        fillColor : '#926239',
        fillOpacity: .3,
        strokeColor : '#6c6c6c',
        strokeWeight: 2,
      // turn this on to allow editing of the created poly
        // clickable: true,
        // editable: true,
        // zIndex: 1,
      },
      rectangleOptions : {
          fillColor : '#926239',
          fillOpacity : 0.3,
          strokeColor : '#6c6c6c',
          strokeWeight : 2,
          // editable: true,
          // draggable: true
      }
    });
    drawingManager.setMap(mymap);

    let boundsUpdated = (currPolygonSelection) => {
      // this is easier to do on client with google maps api but more efficient to do on server
      // extract points from google map Polygon - assumes it is a simple polygon with one path https://developers.google.com/maps/documentation/javascript/reference/polygon#Polygon.getPaths
      setFilters({...filters, bounds: currPolygonSelection.getPath().getArray().map(point => point.toJSON()), neighborhood: ""});
      // delete filters.neighborhood;
    }
    let rectBoundsUpdated = (currRectSelection) => {
      const ne = currRectSelection.getBounds().getNorthEast()
      const sw = currRectSelection.getBounds().getSouthWest();
      const rectBounds = [{lat: ne.lat(), lng: ne.lng()}, {lat: sw.lat(), lng: ne.lng()}, {lat: sw.lat(), lng: sw.lng()}, {lat: ne.lat(), lng: sw.lng()}];
      setFilters({...filters, bounds: rectBounds, neighborhood: ""});
    }

    // event handlers for user polygon drawings
    // https://developers.google.com/maps/documentation/javascript/reference/polygon#Polygon
    const c = google.maps.event.addListener(drawingManager, 'polygoncomplete', (e) => {
      clearMapOverlays();
      setPolygonSelection(e);
      // this doesnt work
      // https://developers.google.com/maps/documentation/javascript/shapes#dragging_events
      // google.maps.event.addListener(polygonSelection.getPath(), 'set_at', function(ex,ex2) {
      //   boundsUpdated();
      // });
      boundsUpdated(e);
    });
    const d = google.maps.event.addListener(drawingManager, 'rectanglecomplete', (e) => {
      clearMapOverlays();
      setRectSelection(e);
      rectBoundsUpdated(e);
    });

    // Define the rectangle and set its editable property to true.
    // rectangle = new google.maps.Rectangle({
    //   bounds: bounds,
    //   editable: true,
    //   draggable: true,
    // });

    // map.addListener("mousedown", (e) => {
    //   bounds.south = e.lat;
    //   bounds.west = e.lng;
    //   rectangle.clear(map);
    //   rectangle = new google.maps.Rectangle({
    //     bounds: bounds,
    //     editable: true,
    //     draggable: true,
    //   });
    // });
    setMap(mymap);
    // teardown function
    return function() {
      console.log("GoogleMap tearing down maps", cc);
      google.maps.event.removeListener(a);
      google.maps.event.removeListener(b);
      google.maps.event.removeListener(c);
      google.maps.event.removeListener(d);
    };
  }

  useEffect(() => {
     // it can be initialized spuriously by react because react is fucking confusing and useffect(func, []) does not fire when it should
    let teardown = initializeMapObject();
    return teardown;
  }, []);

  useEffect(() => {
    if (centerPlace) centerMapOnPlace(centerPlace);
  }, [centerPlace]);


  useEffect(() => {
    let teardown = showListings(listings);
    return teardown;
  }, [listings]);

  // if the rectangle selection is changed, remove the old one
  useEffect(() => {
    return () => { if (rectSelection) rectSelection.setMap(null) }
  }, [rectSelection]);
  useEffect(() => {
    return () => { if (searchLocRectangle) searchLocRectangle.setMap(null) }
  }, [searchLocRectangle]);
  useEffect(() => {
    return () => { if (searchLocMarker) searchLocMarker.setMap(null) }
  }, [searchLocMarker]);
  useEffect(() => {
    return () => { if (polygonSelection) polygonSelection.setMap(null) }
  }, [polygonSelection]);


  return (
    <div>
      <AddressSearch setCenterPlace={setCenterPlace} map={map} />
      <div id="mapcontainer">
        <div ref={ref} id="map" />
      </div>
    </div>
  );
}


  


export default function GoogleMap(props) {
  const { listings, filters, setFilters, setLoadingStatusMessage, centerPlace, setCenterPlace } = props;

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>Loading Map</div>;
      case Status.FAILURE:
        return <div>Map Load Error!</div>;
      case Status.SUCCESS:
        return (
            <MyMapComponent listings={listings} filters={filters} setFilters={setFilters} setCenterPlace={setCenterPlace} setLoadingStatusMessage={setLoadingStatusMessage} centerPlace={centerPlace} />
        );
    }
  };

  return (
    <div className="map">
      <Wrapper apiKey={"AIzaSyBl_2ZQ3qxD8sVVctT3faazr2uVkxLJUXQ"} render={render} libraries={["places,drawing"]}>
      </Wrapper>
    </div>
  );
}
