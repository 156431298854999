import React, { useState, useEffect } from 'react';

// https://create-react-app.dev/docs/proxying-api-requests-in-development/
const BACKEND_SERVER = "//" + process.env.REACT_APP_BACKEND_HOST + ':' + process.env.REACT_APP_BACKEND_PORT;
const BACKEND_API = BACKEND_SERVER + "/api/listings";
// console.log("backend host", BACKEND_API);

function ListingsRetriever(props) {
  let { filters, setLoadingStatusMessage, setListings, setWarnings } = props;

  const refreshListingsFromServer = () => {
    if (Object.keys(filters).length == 0) {
      console.log("Not refreshing from server, no filters specified");
      return null;
    }
    setLoadingStatusMessage("Getting data from server");
    console.log("Query server", filters);
    fetch(BACKEND_API, {method: 'POST', headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }, body: JSON.stringify(filters)})
      .then(result => result.json())
      .then(result => {
        if (!result.success) {
          console.log("ajax failure", result);
          alert("ajax failure " + result.message);
          setLoadingStatusMessage(false);
          return;
        }
        console.log("ListingsRetriever:", result.results);

        if (result.results.length >= 200) {
          setWarnings("Since there are over 200 results, we don't show competitors data");
        } else if (result.results.length >= 1000) {
          setWarnings("Too many results, we are only showing the first 1000 results. Also, we dont show competitors data");
          result.results = result.results.slice(0, 1000);
        } else {
          setWarnings("");
        }

        setListings(result.results);
        // setLoadingStatusMessage(true, "Loading data into map");
        // showListings(masterlistings);
        setLoadingStatusMessage(false);
        return result.results;
      }, (error, error2) => {
        console.log("ajax error", error, error2);
        setLoadingStatusMessage(false);
      });
  }

  // https://reactjs.org/docs/hooks-effect.html
  // basically runs if the component is ever required to "repaint"
  useEffect(() => {
    refreshListingsFromServer();
  }, [filters]); // only refresh listings if the filters change

  return null; // doesnt generate any UI
}

export default ListingsRetriever;