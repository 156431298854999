import * as React from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Brush } from 'recharts';

export default function ChartQuantityByPrice(props) {
  const { listings } = props;

  const data = {};
  for (const listing of listings) {
    const x = Math.round(listing.house.price_sold_int / 100000 * 2) / 2 / 10; // this rounds to the nearest .05M
    if (!data[x]) {
      data[x] = 1;
    } else {
      data[x]++;
    }
  }
  const dataArr = [];
  for (const x of Object.keys(data)) {
    dataArr.push({house_price: Number(x), quantity: data[x]});
  }
  dataArr.sort((a, b) => a.house_price - b.house_price); // default sort behavior with numbers is alphabetical, which is NOT what we want

  // https://recharts.org/en-US/guide/getting-started
  return (
    <BarChart width={1200} height={300} data={dataArr}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="house_price" unit="M" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="quantity" fill="#8884d8" />
    </BarChart>
  );
}
