import React, { useState } from 'react';
import ChartQuantityByPrice from './ChartQuantityByPrice';
import ChartQuantityByDate from './ChartQuantityByDate';

export default function ListingsStats(props) {
  const { listings } = props;
  const [ priceChartToggle, setPriceChartToggle ] = useState(false);
  const [ dateChartToggle, setDateChartToggle ] = useState(false);

  const avgs = (data) => {
    let results = { mean: 0, median: 0};
    if (!data.length) return results;
    results.mean = Math.round(data.reduce((acc, size) => acc + size) / data.length);
    data.sort((a,b) => a - b);
    results.median = data.length % 2 == 1 ? data[(data.length - 1)/2] : (data[data.length/2-1] + data[data.length/2]) / 2;
    results.median = Math.round(results.median);
    // console.log(data);
    // console.log(results.median);
    return results;
  }

  let lot = listings ? avgs(listings.map(listing => listing.house.lot_size)) : 0;
  let sold = listings ? avgs(listings.map(listing => listing.house.price_sold_int)) : 0;
  let count = listings ? listings.length : 0;
  return (
    <div id='stats'>
      <div className='stat'><b>Houses:</b>{count}</div>
      <div className='stat'>
        <span><b>Lot</b> mean: {lot.mean.toLocaleString()} median: {lot.median.toLocaleString()}</span>
      </div>
      <div className='stat'>
        <span><b>Sold</b> mean: $ {sold.mean.toLocaleString()} median: ${sold.median.toLocaleString()}</span>
      </div>
      <input type="button" value="Show Price Graph" onClick={() => setPriceChartToggle(!priceChartToggle)} />
      <input type="button" value="Show Date Graph" onClick={() => setDateChartToggle(!dateChartToggle)} />
      { priceChartToggle ? <ChartQuantityByPrice listings={listings} /> : null }
      { dateChartToggle ? <ChartQuantityByDate listings={listings} /> : null }
    </div>
  );
      // <a href='javascript:void(0)' onclick='toggleShow(document.getElementById(\"myChartContainer\"))'>Show volume graph</a>
      // <a href='javascript:void(0)' onclick='toggleShow(document.getElementById(\"soldPriceChartContainer\"))'>Show price graph</a>
}
