import React, { useState, useEffect } from "react";
import './App.css';
import Search from './Search';
import Summary from './Summary';
import Warnings from './Warnings';
import GoogleMap from './GoogleMap';
import ListingsChart from './ListingsChart';
import ListingsStats from './ListingsStats';
import ListingsRetriever from './ListingsRetriever';
import LoadingStatus from './LoadingStatus';
import { useParams } from 'react-router-dom';
// import Test from './Test';

// use functions not components
// https://reactjs.org/docs/hooks-faq.html
const today = new Date();
let todayMonthM = today.getMonth()+1;
if (todayMonthM < 10) todayMonthM = '0' + todayMonthM;
const todayMonth = today.getFullYear() + "-" + todayMonthM;

export default function App() {
  const { neighborhood, startdate } = useParams();

  if (neighborhood && startdate) {
    document.title = "Report for " + neighborhood + ", " + startdate;
  }

  const [listings, setListings] = useState([]);
  const [filters, setFilters] = useState({
    minPrice: 0,
    maxPrice: 5000000,
    lotMin: null,
    lotMax: null,
    startDate: '2022-01',
    endDate: todayMonth,
    neighborhood: "",
    detached: true,
    semidetached: true,
    freeholdth: false,
    condoth: false,
    condoapt: false,
    basement_finished: false,
    basement_separate: false,
    basement_walkout: false,
    bounds: null,
    br: false,
    ba: false,
    garage: false,
  });

  const [warnings, setWarnings] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [centerPlace, setCenterPlace] = useState(false);
  const setLoadingStatusMessage = (message) => {
    if (message) {
      setLoadingStatus(true);
      setLoadingMessage(message);
    } else {
      setLoadingStatus(false);
      setLoadingMessage(null);
    }
  }

  // input param is a list which ones to update
  // const updateFilterValues = (filterValues) => {
  //   let newFilters = {...filters}; // shallow copy
  //   for (let filterKey of Object.keys(filterValues)) {
  //     newFilters[filterKey] = filterValues[filterKey];
  //   }
  //   setFilters(newFilters);
  // }

  return (
    <div>
      <ListingsRetriever filters={filters} setLoadingStatusMessage={setLoadingStatusMessage} setListings={setListings} setWarnings={setWarnings} />
      <LoadingStatus loadingStatus={loadingStatus} loadingMessage={loadingMessage} />
      <div className="search-panel">
        <GoogleMap listings={listings} setLoadingStatusMessage={setLoadingStatusMessage} filters={filters} setFilters={setFilters} setLoadingStatusMessage={setLoadingStatusMessage} centerPlace={centerPlace} setCenterPlace={setCenterPlace} />
        <Search neighborhood={neighborhood} startdate={startdate} filters={filters} setFilters={setFilters} />
      </div>
      <h3>Neighbourhood Home Sales History</h3>
      <Summary filters={filters} listings={listings} />
      <ListingsStats listings={listings} />
      <Warnings warnings={warnings} />
      <ListingsChart listings={listings} />
    </div>
  );
  //       <Test listings={listings} />
}
