import { capitalize } from './Utils.js';

function Summary(props) {
  const { filters, listings } = props;

  if (!filters || !listings || listings.length == 0)
    return <div>No summary</div>;

  let numListings = listings.length;
  let meanDays = numListings ? Math.round(listings.reduce((acc, curr) => acc + curr.house.list_days, 0) / numListings) : 0;
  let listdays = listings.map(x => x.house.list_days).sort();
  let half = Math.floor(listdays.length / 2);  
  let medianDays = listdays.length % 2 ? listdays[half] : (listdays[half - 1] + listdays[half]) / 2.0;
  let adj = medianDays > 10 ? "slowly" : medianDays < 5 ? "quickly" : "";

  let aboveAsking = listings.map(x => (x.house.price_sold_int - x.house.price_int) / x.house.price_int).sort();
  half = Math.floor(aboveAsking.length / 2);  
  let medianAboveAsking = aboveAsking.length % 2 ? aboveAsking[half] : (aboveAsking[half - 1] + aboveAsking[half]) / 2.0;
  let aboveA = Math.round(medianAboveAsking * 100);
  if (aboveA > 0) aboveA = aboveA +"% above";
  else if (aboveA < 0) aboveA = Math.abs(aboveA) +"% below";
  else aboveA = "% of";

  let neighborhood = filters.neighborhood ? capitalize(filters.neighborhood) : "this neighborhood";

  return (
    <div>
      In {neighborhood}, from <b>{filters.startDate}</b> to <b>{filters.endDate}</b>, <b>{numListings}</b> {filters.detached ? "detached":""} {filters.semidetached ? "semi-detached":""} homes sold for a median of <b>{aboveA}</b> asking price. 
  They sold {adj} with a median <b>{medianDays}</b> days on market and an average of <b>{meanDays}</b> days on market.
    </div>
  );
}

export default Summary;