import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const YEAR_IN_MS = 86400000 * 365;
// returns number from [0,1]
function calculateAppreciation(sale1, sale2) {
  const price2 = Number(sale2.price_sold.replace(/[^0-9\.-]+/g,""));
  const price1 = Number(sale1.price_sold.replace(/[^0-9\.-]+/g,""));
  const dateDiffInYears = (new Date(sale2.date_end) - new Date(sale1.date_end)) / YEAR_IN_MS;
  return { appreciation: Math.round((Math.pow(price2/price1, 1/dateDiffInYears) - 1) * 10000) / 100, years: Math.round(dateDiffInYears * 10) / 10, years_str: sale1.date_end + " to " + sale2.date_end };
}
function calculateAllAppreciations(sales) {
  let results = [];
  let sale1 = null;
  let sale2 = null;
  for (let i = 0; i < sales.length; i++) { // sales are in reverse chronological order
    let sale = sales[i];
    if (sale.status == 'Sold') {
      if (!sale1) sale1 = sale; // base case
      else { // shift things over
        sale2 = sale1;
        sale1 = sale;
        results.push(calculateAppreciation(sale1, sale2));
      }
    }
  }
  return results;
}

const appreciationRenderer = (params) => {
  return params.data.delta.map((x) => (
    <div>
      <span className={"appreciation " + (x['appreciation'] >=0 ? 'up' : 'down')}>{x['appreciation'] >=0 ? "\u25B2" : "\u25BC"}</span>
      <span>{x['appreciation']}% for </span>
      <span className={"helpcursor"} title={x['years_str']}>{x['years'] + "yrs"}</span>
    </div>
  ))
};

let cols = [
  {field: "date", headerName: "Date", desc: "Date of Sale"},
  {field: "price_sold", headerName: "Sold", desc: "Price Sold", valueFormatter: params => "$" + params.value},
  {field: "delta", headerName: "Appreciation", desc: "Yearly Appreciation and for how many years it appreciated at that yearly rate",
    cellRenderer: appreciationRenderer, cellStyle: {'whiteSpace': 'normal'}, autoHeight: true },
  {field: "dom", headerName: "DOM", desc: "Days on Market"},
  {field: "num_comp", headerName: "Comp", desc: "Number of houses that match this filter criteria and were also active on the market on the date of sale of this property"},
  {field: "link_comp", headerName: "Competitors", desc: "Houses that match this filter criteria and were also active on the market on the date of sale of this property",
    cellRenderer: props => (
      props.data.link_comp.map(competitor => (
        <div>
          <a target='_blank' href={"https://housesigma.com/web/en/house/"+competitor.id}>{competitor.address}</a>
        </div>
        )
      )),
    cellStyle: {'whiteSpace': 'normal'}, autoHeight: true },
  {field: "overasking", headerName: "%Ask", desc: "Percent Sold Over Asking"},
  {field: "price_asking", headerName: "Ask", desc: "Asking Price"},
  {field: "address", headerName: "Address",
    cellRenderer: props => (
      <a href={"https://housesigma.com/web/en/house/"+props.data.address.id_listing+"/"+props.data.address.seo_suffix}
        target="_blank">
        {props.data.address.address}
      </a>
    )
  }, // onmouseover={hoverlisting(props.data.address.id_listing)} 
  {field: "type", headerName: "Type", desc: "Detached, Semi-Detached, or Link"},
  {field: "lot", headerName: "Lot", desc: "Lot Size -- estimated",
    cellRenderer: props => (
      <span className={props.data.lot.lotClass} title={props.data.lot.lotTitle}>{props.data.lot.lot_size}</span>
    )
  },
  {field: "dim_front", headerName: "Front"},
  {field: "dim_depth", headerName: "Depth"},
  {field: "dim_area", headerName: "MLS SqFt", desc: "Based on MLS Listing"},
  {field: "dim_area_est", headerName: "HS SqFt", desc: "Based on HouseSigma's Estimate"},
  {field: "sqft_est", headerName: "Est SqFt", desc: "Sum of all MLS listing room dimensions"},
  {field: "sqft_aboveground_est", headerName: "AbvEst SqFt", desc: "Sum of all MLS listing room dimensions not lower or bsmt"},
  {field: "br", headerName: "BR"},
  {field: "brplus", headerName: "BR+", desc: "Bedroom Plus"},
  {field: "ba", headerName: "BA"},
  {field: "bs", headerName: "Bsmt", desc: "Condition of Basement"},
  {field: "park", headerName: "Prk", desc: "Number of Parking"},
  {field: "garage", headerName: "Grg", desc: "Number of Garage"},
  {field: "ac", headerName: "AC", desc: "Air Conditioning"},
  {field: "heat_fuel", headerName: "HeatS", desc: "Heat Source"},
  {field: "heat_type", headerName: "HeatT", desc: "Heat Type"},
  {field: "tax", headerName: "Tax", desc: "Yearly Property Tax"},
];
cols = cols.map(x => {
  x.resizable = true;
  delete x.desc;
  return x;
});

function getRow(listing) {
  const house = listing.house;
  const appreciations = calculateAllAppreciations(listing.house.listing_history);
  // house.rooms
  // house.front_direction
  // house.build_year
  // house.maintenance
  // house.listing_history
  // house.parking.garage
  // house.list_status
  // house.photo_url
  let lotClass = house.land && house.land.irregular ? "irregular helpcursor" : "";
  let lotTitle = house.land && house.land.irregular || "";
  let land = house.land || {front:0,depth:0};
  let sqftEst = house.rooms.reduce((acc, room) => acc + (room.size_feet ? room.size_feet.split(" x ").reduce((total, dim)=> total * dim) : 0), 0);
  let sqftAboveGroundEst = house.rooms.reduce((acc, room) => acc + (room.size_feet && room.lv != 'Level: Lower' && room.lv != 'Level: Bsmt' ? room.size_feet.split(" x ").reduce((total, dim)=> total * dim) : 0), 0);

  let lot_size = house.lot_size ? house.lot_size.toLocaleString():0;

  return {
    id: listing._id,
    date: new Date(house.date_end).toISOString().split('T')[0],
    price_sold: house.price_sold_int.toLocaleString(),
    delta: appreciations,
    dom: house.list_days,
    num_comp: house.num_active_competitors_on_sold_date ? house.num_active_competitors_on_sold_date : '',
    link_comp: house.num_active_competitors_on_sold_date ? house.competitors : [],
    overasking: Math.round((house.price_sold_int - house.price_int) / house.price_int * 100) + "%",
    price_asking: house.price_int.toLocaleString(),
    address: {id_listing: house.id_listing, seo_suffix: house.seo_suffix, address: house.address },
    type: house.house_type_name,
    lot: {lotClass, lotTitle, lot_size},
    dim_front: land.front,
    dim_depth: land.depth,
    dim_area: house.house_area.area.toLocaleString(),
    dim_area_est: house.house_area.estimate ? house.house_area.estimate.toLocaleString() : "",
    sqft_est: sqftEst.toLocaleString(),
    sqft_aboveground_est: sqftAboveGroundEst.toLocaleString(),
    br: house.bedroom,
    brplus: house.bedroom_plus || "",
    ba: house.washroom,
    bs: house.basement,
    park: house.parking.parking,
    garage: house.parking.garage,
    ac: house.air_condition,
    heat_fuel: house.heat_fuel,
    heat_type: house.heat_type,
    tax: "$"+house.tax_int,
  };
}

export default function ListingsChart(props) {
  const { listings } = props;

  const rows = listings.map(listing => getRow(listing));
  return (
    <div className="ag-theme-alpine" style={{height: 400, width: "100%"}}>
       <AgGridReact
           rowData={rows}
           columnDefs={cols}
           defaultColDef={{sortable: true}}
           onFirstDataRendered={(event) => event.columnApi.autoSizeAllColumns(true)}
        />
   </div>
  );
/*
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow key="header">
            {cols.map((col) => (
              <TableCell>{col.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.address}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="right">{row.price_sold}</TableCell>
              <TableCell align="right">{row.delta}</TableCell>
              <TableCell align="right">{row.dom}</TableCell>
              <TableCell align="right">{row.num_comp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    */

}
