import React, { useState, useEffect } from 'react';

function Search(props) {
  let { neighborhood, startdate, filters, setFilters } = props;

  // let [neighborhood, setNeighborhood] = useState(null);
  // const [startDate, setStartDate] = useState(null);


  const setNeighborhood = (val) => {
    setFilters({...filters, neighborhood: val.toLowerCase().trim()});
  }
  useEffect(() => {
    if (neighborhood) {
      setNeighborhood(neighborhood);
    } else {
      setNeighborhood("");
    }
  }, [neighborhood]);
  useEffect(() => {
    if (startdate) {
      // fixme
      const endDate = startdate + 1;
      setFilters({...filters, startDate: startdate, endDate: endDate});
    }
  }, [startdate]);

  const donothing = () => {}

  return (
    <div className="filters">
      <h3>Step 2: Refine Your Search</h3>
      <form className="filters">
        <div className="form-group row">
          <input type="text" id="neighborhood" name="neighborhood" placeholder="The Neighborhood" value={filters.neighborhood} onChange={donothing} onBlur={e => setFilters({...filters, [e.target.id]: e.target.value.toLowerCase().trim()})} />
        </div>
        <div className="form-group row">
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="detached" checked={filters.detached} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="detached">
              Detached
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="semidetached" checked={filters.semidetached} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="semidetached">
              Semi-Detached
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="freeholdth" checked={filters.freeholdth} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="freeholdth">
              Freehold Townhouse
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="condoth" checked={filters.condoth} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="condoth">
              Condo Townhouse
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="condoapt" checked={filters.condoapt} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="condoapt">
              Condo Apartment
            </label>
          </div>
        </div>
        <div className="form-group row">
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="basement_finished" checked={filters.basement_finished} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="basement_finished">
              Basement Finished
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="basement_separate" checked={filters.basement_separate} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="basement_separate">
              Basement Separate Entrance
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" value="" id="basement_walkout" checked={filters.basement_walkout} onChange={e => setFilters({...filters, [e.target.id]: e.target.checked})} />
            <label className="form-check-label" htmlFor="basement_walkout">
              Walkout
            </label>
          </div>
        </div>
        <div className="form-group row">
          <div className="filter date">
            <label htmlFor="startDate">
              Start
            </label>
            <input type="month" id="startDate" min="2011-01" value={filters.startDate} onChange={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
          <div className="filter date">
            <label htmlFor="endDate">
              End
            </label>
            <input type="month" id="endDate" value={filters.endDate} onChange={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
        </div>
        <div className="form-group row">
          <div className="filter lotsize">
            <input type="number" id="lotMin" step="100" pattern="\d*" placeholder="Lot min" value={filters.lotMin} onBlur={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
          <div className="filter lotsize">
            <input type="number" id="lotMax" step="100" pattern="\d*" placeholder="Lot max" value={filters.lotMax} onBlur={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
        </div>
        <div className="form-group row">
          <div className="filter price">
            <div id="minPriceStr">${parseInt(filters.minPrice).toLocaleString()}</div>
            <label className="" htmlFor="minPrice">
              Min
            </label>
            <input type="range" min="0" max="5000000" value="0" className="slider" id="minPrice" step="100000" value={filters.minPrice} onChange={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
          <div className="filter price">
            <div id="maxPriceStr">${parseInt(filters.maxPrice).toLocaleString()}</div>
            <label className="" htmlFor="maxPrice">
              Max
            </label>
            <input type="range" min="0" max="5000000" value="5000000" className="slider" id="maxPrice" step="100000" value={filters.maxPrice} onChange={e => setFilters({...filters, [e.target.id]: e.target.value})} />
          </div>
        </div>
        <div className="form-group row">
          <div>
            <div>Bedrooms</div>
            <div className="brselector">
              <input type="radio" id="brall" name="br" value="all" checked={!filters.br} onChange={e => setFilters({...filters, [e.target.name]: null})} />
              <label htmlFor="brall">All</label>
              <input type="radio" id="br1" name="br" value="1" checked={filters.br == 1} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="br1">1+</label>
              <input type="radio" id="br2" name="br" value="2" checked={filters.br == 2} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="br2">2+</label>
              <input type="radio" id="br3" name="br" value="3" checked={filters.br == 3} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="br3">3+</label>
              <input type="radio" id="br4" name="br" value="4" checked={filters.br == 4} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="br4">4+</label>
              <input type="radio" id="br5" name="br" value="5" checked={filters.br == 5} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="br5">5+</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div>
            <div>Bathrooms</div>
            <div className="baselector">
              <input type="radio" id="baall" name="ba" value="all" checked={!filters.ba} onChange={e => setFilters({...filters, [e.target.name]: null})} />
              <label htmlFor="baall">All</label>
              <input type="radio" id="ba1" name="ba" value="1" checked={filters.ba == 1} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="ba1">1+</label>
              <input type="radio" id="ba2" name="ba" value="2" checked={filters.ba == 2} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="ba2">2+</label>
              <input type="radio" id="ba3" name="ba" value="3" checked={filters.ba == 3} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="ba3">3+</label>
              <input type="radio" id="ba4" name="ba" value="4" checked={filters.ba == 4} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="ba4">4+</label>
              <input type="radio" id="ba5" name="ba" value="5" checked={filters.ba == 5} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="ba5">5+</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div>
            <div>Garage/Parking</div>
            <div className="garageselector">
              <input type="radio" id="garageall" name="garage" value="all" checked={!filters.garage} onChange={e => setFilters({...filters, [e.target.name]: null})} />
              <label htmlFor="garageall">All</label>
              <input type="radio" id="garage1" name="garage" value="1" checked={filters.garage == 1} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="garage1">1+</label>
              <input type="radio" id="garage2" name="garage" value="2" checked={filters.garage == 2} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="garage2">2+</label>
              <input type="radio" id="garage3" name="garage" value="3" checked={filters.garage == 3} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="garage3">3+</label>
              <input type="radio" id="garage4" name="garage" value="4" checked={filters.garage == 4} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="garage4">4+</label>
              <input type="radio" id="garage5" name="garage" value="5" checked={filters.garage == 5} onChange={e => setFilters({...filters, [e.target.name]: parseInt(e.target.value)})} />
              <label htmlFor="garage5">5+</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="polygon">
            <input type="text" id="polygonname" list="polygonnames" name="polygonname" placeholder="Boundary Label" />
            <datalist id="polygonnames">
              <option value="first name" />
              <option value="second name" />
            </datalist>
          </div>
        </div>
      </form>
    </div>
  );

//          <a href="javascript:void(0)" onclick="polygonManager.loadPolygon();">Load</a>
//          <a href="javascript:void(0)" onclick="polygonManager.savePolygon();">Save</a>
//          <a href="javascript:void(0)" onclick="polygonManager.deletePolygon();">Delete</a>
}

// because fucking react wont allow hooks (userParams) in components. what the fuck?
// https://www.codecheef.org/article/how-to-use-useparams-hook-in-class-component-in-react
// https://stackoverflow.com/questions/70999713/react-router-v6-access-route-params-and-pass-as-props
export default Search;